


















































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import * as types from '@/store/account/types';
import VButton from '@/components/VButton.vue';
import { AxiosResponse } from 'axios';
import emailValidator from '@/utilities/validators/email';
import ValidatedForm from '@/utilities/validation/validatedform';
import FormField from '@/utilities/validation/formfield';

@Component({
  components: { VButton },
})
export default class Register extends Vue {

  @Prop({ default: false }) private nbbuRegistration!: boolean;
  @Prop({ default: 'Registreren gratis proefperiode' }) private header!: string;
  @Action('register', { namespace: 'account' }) private register!: types.RegisterAction;

  private invalid: boolean = false;
  private completed: boolean = false;
  private submitting: boolean = false;
  private serverErrors: { [key: string]: string } = {};
  private passwordField = new FormField((value, dirty) => value.length > 5, '');
  private repeatField = new FormField((value, dirty) => value.length > 5 && value === this.passwordField.value, '');

  // TODO: use ValidatedForm instead

  private registerForm = new ValidatedForm({
    companyName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    email: new FormField(
      // TODO: this reference does not work when passed to formfield
      (value, dirty) => 'email' in this.serverErrors ? true : emailValidator(value, dirty), '',
    ),
    firstName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    lastName: new FormField((value: any, dirty: boolean) => value !== '', ''),
    password: this.passwordField,
    repeatPassword: this.repeatField,
    termsChecked: new FormField((value, dirty) => value === 'checked', 'unchecked'),
    nbbuNumber: new FormField((value, dirty) => !this.nbbuRegistration || value.length > 0, ''),
  });

  get registerFormValid() {
    return this.registerForm.fieldValidity();
  }

  private submit() {
    if (this.registerForm.formValid()) {
      this.submitting = true;
      this.register({
        email: this.registerForm.fields.email.value,
        company: this.registerForm.fields.companyName.value,
        password: this.registerForm.fields.password.value,
        firstName: this.registerForm.fields.firstName.value,
        lastName: this.registerForm.fields.lastName.value,
        nbbuNumber: this.registerForm.fields.nbbuNumber.value,
      }).then((response: {}) => {
        this.completed = true;
      }).catch((error: AxiosResponse) => {
          if (error.status === 400) {
            this.serverErrors = error.data;
          } else {
            this.serverErrors = { general: 'Er is iets fout gegaan. Probeer het later opnieuw.' };
          }
      }).finally(() => {
        this.submitting = false;
      });
    }
  }
}
